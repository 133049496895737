import * as React from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import toast from "react-hot-toast"
import { saveFormSubmission } from "../../utils/helpers"

const ContactForm = () => {
	const activeEnv = process.env.GATSBY_ACTIVE_ENV
	const notify = () => toast(`Thank you for your enquiry. We'll be in touch soon.`, {
		position: "top-center",
		id: "clipboard",
	})
  return (
  	<Formik
  		validateOnChange={values => {
	      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	      const errors = {};
	      if (!values.first_name) {
	        errors.first_name = `First name is required`
	      }
	      if (!values.last_name) {
	        errors.last_name = `Last name is required`
	      }
	      if (!values.email || !emailRegex.test(values.email)) {
	        errors.email = `A valid email address is required`
	      }
	      if (!values.postcode) {
	        errors.postcode = `Postcode/City is required`
	      }
	      if (!values.phone) {
	        errors.phone = `Phone is required`
	      }
	      if (values.state === 'none') {
	        errors.state = `State is required`
	      }
	      if (values.contact_method === 'none') {
	        errors.contact_method = `Contact method is required`
	      }
	      return errors
	    }}
  		validateOnBlur={false}
	    initialValues={{
	      first_name: ``,
	      last_name: ``,
	      email: ``,
	      phone: ``,
	      postcode: ``,
	      state: `none`,
	      situation: `none`,
	      contact_options: ``,
	      status: `none`,
	      suburb_town: ``,
	      contact_method: ``,
	      approved_sda_category: `none`,
	      shared_living_status: `none`,
	      message: ``,
	    }}
	    onSubmit={(values, { setSubmitting, resetForm }) => {
	    	let data = values
	    	data.webform_id = `contact`
    		saveFormSubmission(data).then(response => {
	      	if (response.status === 200) {
	      		setSubmitting(true)
	      		resetForm()
	      		notify()
	      	}	else {
	      		console.log(response)
	      		setSubmitting(false)
	      	}
	      })
	    }}
	    validate={values => {
	      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	      const errors = {};
	      if (!values.first_name) {
	        errors.first_name = `First name is required`
	      }
	      if (!values.last_name) {
	        errors.last_name = `Last name is required`
	      }
	      if (!values.email || !emailRegex.test(values.email)) {
	        errors.email = `A valid email address is required`
	      }
	      if (!values.postcode) {
	        errors.postcode = `Postcode/City is required`
	      }
	      if (!values.phone) {
	        errors.phone = `Phone is required`
	      }
	      if (values.state === 'none') {
	        errors.state = `State is required`
	      }
	      return errors
	    }}
	  >
	  {({ values, handleChange, handleBlur, setFieldValue }) => (
	    <Form>
	     	<fieldset className="-mt-4 grid grid-cols-2 gap-6">
	     	  <legend className="mb-6 uppercase text-sm">Your details</legend>
		      <div className="relative">
			      <label className={values.first_name ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="first_name">First name <span className="required">*</span></label>
			      <Field type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" name="first_name" placeholder="First name" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="first_name" />
		      </div>

		      <div className="relative">
			      <label className={values.last_name ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="last_name">Last name <span className="required">*</span></label>
			      <Field name="last_name" type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Last name" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="last_name" />
		      </div>

		      <div className="relative">
			      <label className={values.email ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="email">Email <span className="required">*</span></label>
			      <Field name="email" type="email" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Email" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="email" />
		      </div>

		      <div className="relative">
			      <label className={values.phone ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="phone">Phone <span className="required">*</span></label>
			      <Field name="phone" type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Phone" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="phone" />
		      </div>

		      <div className="relative">
			      <label className={values.postcode ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="postcode">Postcode/City <span className="required">*</span></label>
			      <Field 
			      		name="postcode"
			      		type="text" 
			      		className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" 
			      		placeholder="Postcode/City" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="postcode" />
		      </div>

		      <div className="relative">
						<label className={values.state === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="state">State <span className="required">*</span></label>
				  	<Field
				     component="select"
				     className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
				     name="state">	
							<option value="none">- Select state -</option>
							<option value="ACT">Australian Capital Territory</option>
							<option value="NSW">New South Wales</option>
							<option value="NT">Northern Territory</option>
							<option value="QLD">Queensland</option>
							<option value="SA">South Australia</option>
							<option value="TAS">Tasmania</option>
							<option value="VIC">Victoria</option>
							<option value="WA">Western Australia</option>
				  	</Field>
		        <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="state" />
	        </div>
	      </fieldset>

	      <fieldset className="mt-12 grid grid-cols-2 gap-6">
	      	<legend className="my-6 uppercase text-sm">Your current situation</legend>
	      	<div className="relative">
	 					<label className={values.situation === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="situation">Your role</label>
		          <Field
		             component="select"
		             className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
		             name="situation">
		            <option value="none">- Select your role -</option>
                <option value="Interested in a new home for myself">I am interested in a new home for myself</option>
                <option value="A parent/carer of someone seeking a new home">I am a parent/carer of someone seeking a new home</option>
                <option value="A support coordinator for someone seeking a new home">I am a support coordinator for someone seeking a new home</option>
                <option value="Other">Other</option>
		          </Field>
	        </div>

	        <div className="relative">
			    	<label className={values.suburb_town ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="suburb_town">Suburb/Town you want to live in</label>
			      <Field name="suburb_town" type="text" className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" placeholder="Suburb/town you want to live in" />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="suburb_town" />
		      </div>

	      	<div className="relative">
	 					<label className={values.status === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="status">SDA status</label>
	          <Field
	            component="select"
	            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
	            name="status"
	            onChange={(e) => {
	             	handleChange(e)
	             	if (e.target.value !== `Approved`) {
	             		setFieldValue('approved_sda_category', 'none')
	             		setFieldValue('shared_living_status', 'none')
	             	}
	            }}>	
	           	<option value="none">- Select SDA status -</option>
              <option value="Approved">Approved</option>
              <option value="Have applied">Have applied</option>
              <option value="Need help applying">Need help applying</option>
              <option value="Not applicable">Not applicable</option>
	          </Field>
	          <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="status" />
	        </div>
	           
	       <div className={values.status === 'Approved' ?  "relative" : "hidden"} >
	 					<label className={values.approved_sda_category === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="approved_sda_category">Approved SDA category</label>
	          <Field
	             component="select"
	             className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
	             name="approved_sda_category">	
	           	<option value="none">- Select approved SDA category -</option>
              <option value="Improved liveability">Improved liveability</option>
              <option value="Fully accessible">Fully accessible</option>
              <option value="Robust">Robust</option>
              <option value="High physical support">High physical support</option>
              <option value="Not sure">Not sure</option>
	          </Field>
	          <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="approved_sda_category" />
	      </div>

				<div className={values.status === 'Approved' ?  "relative" : "hidden"} >
	 				<label className={values.shared_living_status === 'none' ?  "hidden" : "absolute text-xs left-2 -top-2 bg-white px-2"} htmlFor="shared_living_status">Approved share status</label>
	        <Field
	           component="select"
	           className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500"
	           name="shared_living_status">	
	         	<option value="none">- Select approved share status -</option>
            <option value="Single occupant">Single occupant</option>
            <option value="Share with one other">Share with one other</option>
            <option value="Share with two others">Share with two others</option>
	       	</Field>
	        <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="shared_living_status" />
       </div>
	    </fieldset>

			<fieldset className="mt-12 grid grid-cols-1 gap-6">
	      		<legend className="mb-6 uppercase text-sm">Preferred contact method</legend>

	           <div id="contact-method" className="field">
					<div role="group" aria-labelledby="contact_method">
						<label className="mr-6">
							<Field type="radio" name="contact_method" value="email" className="mr-2 relative t-1" />
							Email
						</label>
					<label className="mr-6">
					<Field type="radio" name="contact_method" value="text" />
						Text
					</label>
					<label className="mr-6">
					<Field type="radio" name="contact_method" value="phone" />
						Phone
					</label>
				</div>
				</div>
	      </fieldset>

	      <fieldset className={values.contact_method === 'phone' ?  "mt-12 grid grid-cols-1 gap-6" : "hidden"} >
	      	<legend className="mb-6 uppercase text-sm">When should we get in touch?</legend>

	           <div id="choose-day" className="field">
					<div role="group" aria-labelledby="contact_options">
						<label className="mr-6">
							<Field type="radio" name="contact_options" value="anytime" className="mr-2 relative t-1" />
							Anytime
						</label>
					<label className="mr-6">
					<Field type="radio" name="contact_options" value="days" />
						I'd like to choose the time and day
					</label>
				</div>
				</div>

			<div className={values.contact_options === 'days' ?  "relative" : "hidden"} >
			<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
			  <table className="min-w-full table-fixed divide-y divide-gray-300">
				  <tbody className="divide-y divide-gray-200 bg-gray-50">
					  <tr id="monday-group" className="field">
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm">Monday</td>
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm" role="group" aria-labelledby="monday-group">
							<label className="mr-6">
								<Field type="checkbox" name="monday" value="AM" className="mr-2 relative t-1" />
									AM
							</label>
							<label>
								<Field type="checkbox" name="monday" value="PM" />
									PM
								</label>
						</td>
					  </tr>
					  <tr id="tuesday-group" className="field">
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm">Tuesday</td>
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm" role="group" aria-labelledby="tuesday-group">
							<label className="mr-6">
								<Field type="checkbox" name="tuesday" value="AM" className="mr-2 relative t-1" />
									AM
							</label>
							<label>
								<Field type="checkbox" name="tuesday" value="PM" />
									PM
								</label>
						</td>
					  </tr>
					  <tr id="wednesday-group" className="field">
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm">Wednesday</td>
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm" role="group" aria-labelledby="wednesday-group">
							<label className="mr-6">
								<Field type="checkbox" name="wednesday" value="AM" className="mr-2 relative t-1" />
									AM
							</label>
							<label>
								<Field type="checkbox" name="wednesday" value="PM" />
									PM
								</label>
						</td>
					  </tr>
					  <tr id="thursday-group" className="field">
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm">Thursday</td>
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm" role="group" aria-labelledby="thursday-group">
							<label className="mr-6">
								<Field type="checkbox" name="thursday" value="AM" className="mr-2 relative t-1" />
									AM
							</label>
							<label>
								<Field type="checkbox" name="thursday" value="PM" />
									PM
								</label>
						</td>
					  </tr>
					  <tr id="friday-group" className="field">
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm">Friday</td>
					 	 <td className="whitespace-nowrap px-3 py-4 text-sm" role="group" aria-labelledby="friday-group">
							<label className="mr-6">
								<Field type="checkbox" name="friday" value="AM" className="mr-2 relative t-1" />
									AM
							</label>
							<label>
								<Field type="checkbox" name="friday" value="PM" />
									PM
								</label>
						</td>
					  </tr>
				  </tbody>
			  </table>
			  </div>
			</div>
 			</fieldset>

			
	      <fieldset className="mt-12 grid grid-cols-1 gap-6">
	      	<legend className="mb-6 uppercase text-sm">Describe how can we help</legend>

			  <div className="relative">
			      <label className={values.message ? "absolute text-xs left-2 -top-2 bg-white px-2" : "hidden"} htmlFor="message">Your message <span className="required">*</span></label>
			      <Field name="message" 
			      		 className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sky-500 focus:ring-sky-500" 
			      		 placeholder="Your message" 
			      		 component="textarea"
			      		 rows="4"
			      		 />
			      <ErrorMessage className="text-xs pt-1 text-red-500" component="div" name="message" />
		      </div>
		      <div className="form-item">
		      	<button aria-label="Submit contact form" type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-sky-650 pt-3 pb-2 px-6 text-base font-semibold text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">Submit</button>
		      </div>
	      </fieldset>
	    </Form>
	  )}
	  </Formik>
	)
}
export default ContactForm